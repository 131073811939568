@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.card-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--cornerradius12);
  transition: all 0.12s ease-in-out;
  position: relative;
  padding-bottom: 5px;
  z-index: 2;
  cursor: pointer;

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &--pointer {
    > * {
      cursor: pointer;
    }
  }
}

.variant {
  .bottom-slot {
    display: flex;
    width: 100%;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    justify-content: center;
  }

  &:hover:not(.variant--tertiary):not(.variant--quaternary) {
    z-index: 10;
    .bottom-slot {
      display: flex;
      opacity: 1;
      margin-top: 10px;
      @include min-1440-break {
        margin-top: 18px;
      }
    }

    .item-rarity-card,
    .item-rarity-shop-card {
      opacity: 0.6;
    }

    .content {
      .item {
        box-shadow: none;
      }

      div[id='top-right-slot'] {
        top: 12px;
        right: 8px;
      }
    }
  }

  &:before {
    transition: all 0.2s ease-in-out;
    position: absolute;
    content: '';
    outline: 2px solid var(--cst-cards-item-primary-outline-hover);
    outline-offset: -2px;
    opacity: 0;
    background: #ffffff;
    border-radius: var(--cornerradius12);
    box-shadow: var(--elevation-1);
    overflow: hidden;

    padding: 6px;

    @include fixedSize(156px, 288px);

    @include min-1440-break {
      @include fixedSize(206px, 376px);
      padding: 8px;
    }
  }

  &:hover:not(.variant--tertiary):not(.variant--quaternary):before {
    opacity: 1;
  }

  &--primary {
    @include fixedSize(140px, 226px);
    @include min-1440-break {
      @include fixedSize(190px, 295px);
    }
  }

  &--secondary {
    @include fixedSize(140px, 226px);

    @include min-1440-break {
      @include fixedSize(183px, 283px);

      .content {
        .item {
          @include fixedSize(183px, 190px);
        }
      }
    }

    &:before {
      @include fixedSize(156px, 288px);
      padding: 6px;

      @include min-1440-break {
        @include fixedSize(199px, 376px);
        padding: 8px;
      }
    }
  }

  &--tertiary {
    //@include fixedSize(188px, 307px);

    @include fixedSize(100%, max-content);

    &:before {
      display: none;
    }
    padding: 10px;
    .content {
      margin-bottom: 12px;
      .item {
        box-shadow: none;
        //@include fixedSize(168px, 168px);
        aspect-ratio: 1 / 1;
      }
    }

    @include min-1440-break {
      //@include fixedSize(252px, 425px);
      @include fixedSize(100%, max-content);
      padding: 18px 14px;
      .content {
        margin-bottom: 18px;
        .item {
          //@include fixedSize(225px, 225px);
          aspect-ratio: 1 / 1;
          box-shadow: none;
        }
      }
    }
  }
  &--quaternary {
    cursor: default;
    @include fixedSize(144px, 238px);
    @include min-1920-break {
      @include fixedSize(163px, 'content');
    }
    .content {
      padding: 12px;
    }
    &:before {
      opacity: 1;
      @include fixedSize(144px, 238px);
      @include min-1920-break {
        @include fixedSize(163px, 249px);
      }
    }
    div[id='top-right-slot'] {
      top: 0;
      right: 0;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  gap: 8px;

  @include min-1440-break {
    &:not(.variant--secondary) {
      gap: 8px;
    }
  }
}

.item-rarity-card,
.item-rarity-shop-card,
.item-rarity-live-feed {
  position: absolute;
}

.item-rarity-shop-card {
  @include fixedSize(100%, 100%);
  aspect-ratio: 1/1;
  // @include singleFixedSize(168px);
  @include min-1440-break {
    //@include singleFixedSize(224px);
  }
}

.item-rarity-card {
  @include singleFixedSize(75px);
  @include min-1440-break {
    @include singleFixedSize(100px);
  }
}

.item-rarity-live-feed {
  @include singleFixedSize(120px);
}

.properties-slot {
  display: flex;
  width: 100%;
  z-index: 10;
}
